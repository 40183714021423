@import "../../../styles/variables";

.sidebarFR{
  display:none;
}

.sidebarBG{
  background-color: #eee; 
  padding: 25px; 
  height: 100%;
}