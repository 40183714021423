.root {
    border-top: 5px solid #E60C19;
    border-bottom: 5px solid #E60C19;
    padding: 30px 40px;
    box-sizing: border-box;
    h1,h2,h3,h4,h5,h6 {
        margin-top: 0;
        margin-bottom: 16px;
    }
    p {
        font-size: 16px;
        line-height: 22px;
        &:last-child {
           margin-bottom: 0; 
        }
    }
}

.noBorderTop {
    border-top: none;
}
.noBorderBottom {
    border-bottom: none;
}
.textWhite {
    color: #fff;
}
.textRed {
    color: #E60C19;
}
.textBlack {
    color: #000
}
.enableDarkBg {
    background-color: #000;
}