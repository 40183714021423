@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {

}

.sideButton {
  @include breakpoint(large) {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}