.root {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
    padding: 60px 75px;
    text-align: center;
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
    }
    @media screen and (max-width: 768px) {
        gap: 40px;
    }
    @media screen and (max-width: 568px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
}

.title {
    font-size: 19px;
    font-weight: bold;
    line-height: 22px;
    color: #FF0000;
    text-transform: none;
}

.description {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
}

.bgDark {
    background-color: #000;
    color: #FFF;
}

.image {
    max-width: 70px;
}
.imageWrapper {
    height: 70px;
}