@import "../../../styles/variables";

.root {
  color: white;
  height: 548px;
  margin-bottom: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding: 16px;
  @include vertical-align;
  @include breakpoint(medium-down) {
    height: 413px;
    background-size: contain;
  }
  @include breakpoint(small) {
    height: 374px;
    margin: 0 -20px 32px;
  }
}

.title{
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin: 0 0 28px;
  text-transform: none;
  text-align: center;
  @include breakpoint(small) {
    margin-bottom: 20px;
    font-size: 36px;
  }
}

.btnWrapper{
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 24px;
  @include breakpoint(medium-down) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}
