@import "../../../styles/variables";

.root {
  @include heleveticaStandard;
  background-color: #D9D9D9;
  margin-bottom: 56px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  > * {
    height: 100%;
  }
  @include breakpoint(small) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 42px;
  }
}

.column1{
  padding: 67px 10px 100px 56px;
  // padding: 50px 56px;
  @include breakpoint(medium-down) {
    padding: 67px 26px 100px 39px;
  }
  @include breakpoint(small) {
    padding: 50px 26px;
    &.smTitle{
      .title{
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
.column2{
  padding: 26px 35px 48px;
  background-color: rgba(83, 83, 83, .1);
  @include breakpoint(small) {
    padding: 44px 26px;
  }
  table{
    border: none;
    tr{
      background-color: transparent !important;
    }
    td{
      padding: 4px 0px;
      font-size: 14px;
      line-height: 30px;
      @include breakpoint(small) {
        line-height: 20px;
      }
    }
    td, th{
      border: none;
    }
    thead{
      td{
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;  
        padding-bottom: 16px;
        @include breakpoint(medium-down) {
          font-size: 14px;
          line-height: 16.1px;
        }
      }
    }
  }
}

.title{
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  margin: 0;
  text-transform: none;
}

.description{
  margin: 36px 46px 0px 0;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  @include breakpoint(small) {
    margin-top: 26px;
  }
  @include target-tablet {
    font-size: 12px;
    line-height: 13.8px;
  }
}
