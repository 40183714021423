@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {
  margin-top: 50px;
  .infoLink {
    font-size: 95%;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    padding-right: 8px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-size: 82%;
    }
  }
  .title {
    font-weight: 600;
    font-stretch: condensed;
    font-size: 30px;
    border-left: 4px solid #d40000;
    padding: 0px;
    margin-top: 0px;
    text-transform: uppercase;
    padding-left: 18px;
    line-height: 1;
    padding-bottom: 10px;
    color: #232323;
    margin-bottom: 17px;
    display: none;
    @include target-mobile() {
      display: block;
    }
  }
  .whereToBuyContainer {
    
  }
  .whereToBuy {
    background-color: $red;
    padding: 10px 15px;
    margin-top: 40px;
    margin-bottom: 0px;
    margin-right: 20px;
    color: white;
    // position: absolute;
    width: calc(100% - 40px);
    // bottom: 0px;
    h3 {
      color: white;
      margin-top: 13px;
    }
  }
  .whereToBuyForm {
    margin: 0;
  }
  .whereToBuyInput {
    width: calc(100% - 100px);
  }
  .whereToBuyInputSubmit {
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      background-color: white;
      color: $red;
    }
  }
  .warrantyInformation {
    margin-bottom: 60px;
    h3 {
      border-bottom: 4px solid $red;
      padding-bottom: 10px;
    }
  }
  .warranty {
    @include clearfix;
  }
  :global {
    .equalize {
      position: relative;
      min-height: 653px;
      margin-bottom: 80px;
      @include target-mobile() {
        min-height: unset;
      }
    }
    // OVERRIDING SLICK SLIDER CSS
    .slick-dots {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        display: inline-block;
        margin: 5px;
        padding: 0px;
        border: none;
      }
    }
    .slick-arrow {
      display: none;
    }
    .slick-slide {
      img {
        width: 100%;
      }
    }
    .helpLinks {
      background-color: $red;
      color: white;
      padding: 22px 29px;
      border-radius: 2px;
      h3 {
        margin-top: 0px;
      }
      a {
        text-align: center;
        text-transform: uppercase;
        display: block;
        background-color: #232323;
        color: white;
        font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
        font-weight: 600;
        font-stretch: condensed;
        padding: 10px;
        margin-top: 10px;
        font-size: 14px;
        &:hover {
          color: $red;
        }
      }
    }
  }
}

.productDetails {

}