@import "../../../styles/variables";

.root.mobile {
  border-top: 3px solid $red;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  display: block;

  @media screen and (min-width: 980px) {
    display: none;
  }

  .navbar {
    padding: 0px 12px;
    position: fixed;
    top: 3px;
    max-width: $max-width-nav;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: $white;
    height: 72px;

    .logo_link {
      img {
        max-height: 50px;
        width: 100%;
        object-fit: contain;
        overflow: hidden;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        padding-right: 12px;
        margin-right: 16px;
      }
    }
  }

  .nav_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .site_title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      margin-left: 12px;
    }
  }

  .nav_right {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .lang_switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .separator {
        display: block;
        padding-left: 3px;
        padding-right: 3px;
      }

      p,
      a {
        padding: 5px 3px;
        font-weight: 700;
        display: block;
        margin: 0;
        position: relative;
        color: #000;
      }

      p {
        font-weight: 700;
      }

      p:after {
        content: "";
        position: absolute;
        left: 3px;
        bottom: 0;
        width: 84%;
        margin: auto;
        height: 2px;
        background-color: $red;
      }

      a:hover:after {
        content: "";
        position: absolute;
        left: 3px;
        bottom: 0;
        width: 84%;
        margin: auto;
        height: 2px;
        background-color: black;
        opacity: 0.2;
        @media screen and (min-width: 980px) {
          font-size: 14px;
        }
      }


    }

    .global_site_link {
      padding: 5px 3px;
      line-height: 1;
    }

    .menu_icon {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 12px;
      border-left: 1px solid rgb(222, 222, 222);
      height: 100%;
    }
  }

  .menu_container {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    height: 100%;
    width: 100%;
    border-top: 1px solid rgb(222, 222, 222);
    top: 75px;
    left: 0;
    z-index: 100;
    transition: transform 400ms ease-in-out;

    .search_box form {
      background-color: white;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;

      .search_input {
        border: none;
        border-bottom: 2px solid black;
        font-size: 21px !important;
        font-style: italic;
        width: 100%;
        display: block;
      }

      button {
        background-color: transparent;
        border: 0;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    ul {
      background-color: white;
      margin: 0;
      padding: 0;

      li {
        width: 100%;
        background-color: white;
        margin: 0;
        padding: 0;
        border-top: 1px solid rgb(222, 222, 222);
        list-style: none;

        button,
        a {
          width: 100%;
          height: 100%;
          padding: 30px 20px;
          display: flex;
          justify-content: space-between;
          background-color: transparent;
          font-size: 21px;
          font-weight: 500;
          border: 0;
          color: $black;
        }
      }

      .sub_menu_container {
        background-color: white;
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: 80vh;
        top: 90px;
        transition: left 400ms ease-in-out;

        .submenu_list_item {
          button,
          a {
            display: flex;
            align-items: center;
            text-align: left;
            color: $black;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 16px 20px;
            background-color: #f6f6f6;
          }
        }

        .sub_menu_title {
          background-color: #000;
          color: #fff;
          padding: 24px 20px;

          h3 {
            margin: 5px 0;
            line-height: 1;
            font-size: 2rem;
          }

          @media screen and (min-width: 400px) {
            // margin: 15px 0;
            font-size: 2.5rem;
          }

          .back {
            font-size: 1.2rem;
            font-weight: 700;
            color: #fff;
            background-color: transparent;
            margin: 0;
            padding: 10px 0 0 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
          }
        }
      }
    }
  }
}

.sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
