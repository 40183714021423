.mt40px {
    margin-top: 40px;
} 
.mb40px {
    margin-bottom: 40px;
} 
.mt0 {
    margin-top: 0;
}
.mb0 {
    margin-bottom: 0;
}

.opacity0 {
    opacity: 0;
}