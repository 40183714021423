@import "../../../styles/variables";

.root {

}

.quote {
  font-style: italic;
  font-size: 20px;
  &:before {
    content: "“";
    font-family: 'HelveticaNeueCondensed-CondensedRegular', sans-serif !important;
    font-stretch: condensed;
    font-size: 120px;
    color: black;
    font-style: normal;
    line-height: 66px;
    vertical-align: bottom;
    margin-top: 10px;
    display: block;
    height: 30px;
    float: left;
  }
}

.cite {
  margin-top: 1em;
  font-style: normal;
  display: block;
}