@import "../../../styles/variables";

.root {
  transition: all 0.1s linear;
  text-decoration: none;
  text-decoration: none;
  display: block;
  margin: 0px 0px 30px 0px;
  &:hover {
    .link_panel__image_holder {
      border: 5px solid $grey;
    }
    .link_panel__header .link_panel_row {
      background-color: $lightGrey;
      padding: 5px 10px;
    }
    .link_panel-home__go, .learn_more_cta {
      background-color: $grey;
    }
    .learn_more_cta:after {
      color: $red;
    }
    color: black;
  }
}

.link_panel__image_holder {
  overflow: hidden;
  height: 200px;
  border: 5px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0px;
  border-right: 0px;
  @include breakpoint(medium) {
    height: 150px;
  }
  @include breakpoint(large) {
    height: 200px;
  }
}

.link_panel__image {
  max-height: none;
  width: 100%;
  height: auto;
}

.link_panel__header {
  padding-bottom: 12px;
  border-bottom: 4px solid $red;
  margin: 11px 0px;
  &:after {
    @include clearfix();
  }
}

.link_panel__heading, {
  font-family: $boldCondensed;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  color: black;
  line-height: 1.4;
  // max-width: 60%;
}

.link_panel_row {
  padding: 0px 0px;
  transition: all 0.1s linear;
  height: 63px;
  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }
}

.link_panel__description {
  margin-bottom: 22px;
  color: black;
  text-decoration: none;
}

.link_panel__header__heading_container {
  width: calc(100% - 60px);
  float: left;
}

.link_panel__header__go_container {
  width: 50px;
  float: right;
}

.link_panel_home__go {
  text-transform: uppercase;
  background-color: $red;
  border-radius: 2px;
  color: white;
  padding: 15px 10px;
  display: inline-block;
  font-family: $boldCondensed;
  font-size: 20px;
  line-height: 1;
}

.link_panel__dropdowns {
  margin-top: -22px;
}