@import "../../../styles/variables";

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 47px 40px;
  margin-top: 36px;
  margin-bottom: 68px;
  @include breakpoint(medium-down) {
    gap: 57px 30px;
  }
  @include breakpoint(small) {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin-bottom: 74px;
  }
}

.itemWrapper{
  @include breakpoint(small) {
    text-align: center;
    .itemIcon{
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.itemTitle{
  @include heleveticaStandard;
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 700;
  text-transform: none;
  line-height: 120%;
  @include breakpoint(medium-down) {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 150%;
  }
}

.itemDescription{
  @include heleveticaStandard;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  margin-top: auto;
  @include breakpoint(medium-down) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  @include breakpoint(small) {
    font-weight: 300;
    line-height: 130%;
  }
}

.itemIcon{
  height: 60px !important;
  margin-top: 20px;
}