@import "../../../styles/variables";

.root {
  background-color: #d40000;
  padding: 30px 25px 50px 25px;
  color: white;
  margin-top: 50px;

  h1, h2, h3, h4, h5 {
    font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    font-weight: 600;
    font-stretch: condensed;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    color: black;
    line-height: 1.4;
    padding-bottom: 23px;
    border-bottom: 4px solid black;
    margin: 22px 0px;
  }
}