@import "../../../styles/variables";

.root {
  background-color: #f3f3f3;
  font-size: 12px;
  margin: 0px;
  padding: 12px;
  padding-bottom: 11px;
  margin-bottom: 12px;
  a {
    color: $black;
    &:hover {
      color: $red;
    }
  }
}

.item {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  border-left: none;
  &:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e901';
    margin: 0px 9px;
    font-size: 80%;

  }
  &:last-child {
    font-weight: bold;
    &:after {
      display: none;
    }
  }
}