@import "../../../styles/variables";

.root{
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.arrowUpIcon{
  width: 61px;
  height: 61px;
  @include breakpoint(medium-down) {
    width: 58px;
    height: 58px;
  }
  @include breakpoint(small) {
    width: 50px;
    height: 50px;
  }
}
