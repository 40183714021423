@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {
  margin: 50px 0px;
  width: 100%;
  margin-bottom: 0px;
  position: relative;
  nav {
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      list-style-type: none !important;
    }
    li {
      border-left: 0px;
      padding-left: 0px;
      margin-bottom: 0px;
      @include breakpoint(medium) {
        border-top: 1px solid #e9e9e9;
        &:last-child {
          border-bottom: 1px solid $lightGrey;
        }
      }
      @include target-mobile {
        &:first-child {
          border-top: 2px solid $lightGrey;
        }
      }
      a {
        @include breakpoint(medium) {
          padding: 10px 0px;
          display: block;
          cursor: pointer;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          color: #555;
          transition: 0.1s all linear;
          display: block;
          position: relative;
          max-width: calc(100% - 20px);
          padding-right: 20px;
          &:hover, &.active {
            padding-left: 10px;
            background-color: $red;
            color: white;
            cursor: pointer;
            max-width: 100%;
            &:after {
              right: -10px;
            }
          }
          &:after {
            content: "\e901";
            float: right;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            padding-right: 15px;
            font-size: 13px;
            margin-top: 5px;
            position: absolute;
            right: -30px;
          }
        }
        @include target-mobile {
          padding-top: 24px;
          border-bottom: 2px solid $lightGrey;
          padding-bottom: 24px;
          font-size: 17px;
          font-weight: 900;
          color: #555555;
          text-transform: uppercase;
          display: block;
          &:after {
            content: "\e901";
            float: right;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            padding-right: 15px;
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.content {
  @include target-mobile {
    display: block;
    position: fixed;
    top: 0px;
    right: -120vw;
    height: 100vh;
    overflow: scroll;
    padding: 0px 20px;
    transition: right 0.05s ease-in;
    background-color: white;
    width: 100%;
    padding-top: 100px;
    z-index: 100;
  }
}

.modalClose {
  display: none;
  @include target-mobile {
    background-color: $red;
    display: block;
    text-align: right;
    color: white;
    font-size: 30px;
    padding: 20px;
    line-height: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
  }
}

.contentTitleClass {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 4px solid $red;
  max-width: 258px;
  margin-top: 0px;
  margin-bottom: 13px;
  @include breakpoint(large) {
    margin-bottom: 33px;
  }
}


.close {
  @include target-mobile {
    display: block;
    position: fixed;
    top: 0px;
    right: 0vw;
    transition: right 0.3s ease-in;
  }
}
