@import "../../../styles/variables";

.root{
  margin-bottom:1rem;
  li {
    margin:0;
    padding:0;
  }
  ul {
    padding-left:1.5rem;
  }
}

.bgImage {
  background-size:cover; 
  background-position:center; 
  height:100%;
}

.order1{
  order: 1;
}

.order2{
  order: 2;
}

.colWrapper{
  padding:0px 10px !important;
}