@import "../../../styles/variables";

.root{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 80px;
  @include breakpoint(medium-down) {
    margin-bottom: 50px;
  }
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.youtubeVideo{
  iframe{
    @extend .video;
  }
}

.playIcon{
  position: absolute;
  z-index: 1;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
}

.videoThumbnail{
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}