@import "../../../styles/variables";

.root {
  font-family: $boldCondensed;
  font-weight: 600;
  font-stretch: condensed;
  font-size: 30px;
  border-left: 4px solid $red;
  padding: 0px;
  margin-top: 36px;
  text-transform: uppercase;
  padding-left: 18px;
  line-height: 1;
  padding-bottom: 10px;
  color: #232323;
  margin-bottom: 10px;
}
.mt0 {
  margin-top: 0;
}