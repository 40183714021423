@import "../../../styles/variables";

.root {
  padding-top: 15px;
  height: 80px;
  @include breakpoint(large) {
    height: auto;
  }
  @include breakpoint(medium) {
    // padding-top: 20px;
    padding-top: 0;
  }
  a {
    color: $black;
    &:hover {
      color: $red;
    }
  }
  .countryName {
    border-left: 1px solid black;
    display: inline-block;
    padding-left: 9px;
    height: 35px;
    padding-top: 8px;
    vertical-align: bottom;
    font-size: 12px;
    position: relative;
    margin-left: -1px;
  }
  .logoLink {
    color: $black;
    &:hover {color: $black;}
    img {
      margin-right: 10px;
    }
  }

}


.inputPanel {
  // border-right: 1px solid $red;
}

.itemgroup {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 10px 10px 0px;
}

.label {
  width: 100%;
  padding-top: 10px;
  font-weight: bold;
}

.input, .select {
  width: 100%;
  padding: 12px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
  appearance: inherit;
  -webkit-appearance: inherit;
  -moz-appearance: inherit;
  -ms-appearance: inherit;
}

.selectwrapper {
  position: relative;
}

.downarrow {
  position: absolute;
  right: 10px;
  top: 10px;
}

.input[type=submit] {
  width: auto;
  float: right;
  background-color: $red;
  color: white;
  font-size: 20px;
  font-family: $boldCondensed;
  font-weight: 600;
  font-stretch: condensed;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background-color: #232323;
  }
}

.fixed {
  position: fixed;
}


.modalwrap {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.95);
  left: 0px;
  display: none;
  z-index: 10001;
  overflow: scroll;
}

.modalForm {
  width: calc(100vw - 60px);
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid $red;
}

.modalHeading {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 4px solid #d40000;
  max-width: 258px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.closeLink {
  // display: block;
  text-align: right;
  float: right;
  font-family: $boldCondensed;
  font-size: 16px;
}

.menu_and_search {
  padding-top: 30px;
  padding-bottom: 13px;
  position: relative;
  @include breakpoint(large)  {
    display: block;
    :global {
      .row {
        flex-flow: row-reverse wrap;
      }
    }
  }
  @include target-tablet-down() {
    display: none;
  }
}

.redBar {
  background-color: $red;
  height: 3px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.mainMenu {
  // only show on desktop
  @include breakpoint(large) {
    margin: 0px;
    list-style-type: none !important;
    li {
      margin-bottom: 0px;
      border-left: none;
      list-style-type: none !important;
    }
    > li {
      text-transform: uppercase;
      padding: 0px;
      display: inline-block;
      margin-right: 14px;
      padding-right: 14px;
      border-right: 1px solid black;
      a {
        border-bottom: 3px solid transparent;
        &:hover {
          color: $black;
        }
      }
      &.active {
        > a {
          border-bottom: 3px solid #ddd !important;
        }
      }
    }
    > li.hasChildren {
      &:hover {
          > ul {
            display: block;
            &:before {
              display: block;
              content: " ";
              height: 30px;
              position: absolute;
              width: 100%;
              top: -32px;
            }
          }
          > a {
            position: relative;
            // border-bottom: 30px solid transparent;
            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 16px solid rgba(0, 0, 0, 0.8);
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              bottom: -19px;
            }
          }
        }
        > ul {
          position: absolute;
          text-transform: none;
          display: none;
          z-index: 1;
          width: 100%;
          left: 0%;
          padding: 0px;
          margin: 0;
          border-top: 16px solid transparent;
          list-style: none;
          clear: both;
          padding-bottom: 20px;
          background-color: rgba(0, 0, 0, 0.8);
          margin-top: 16px;
          > li {
            padding: 0px 50px;
            width: 25%;
            max-width: 980px;
            margin: 0 auto;
            padding: 0px 20px;
            float: left;
            a {
              color: white;
              &:hover {
                color: $red;
              }
            }
            &:first-child {
            }
            &:last-child {
              padding-bottom: 20px;
            }
          }
        }
    }
  }
}


// Mobile styling of main menu

.root {
  @include target-tablet-down() {
    .mainMenu {
      display: none;
    }
    &.menuOpen {
      .mainMenu {
        display: block;
        border-left: none;
        padding: 0px 20px;
        font-size: 12px;
        list-style-type: none !important;
        li {
          border-left: none;
          margin-bottom: 0px;
          list-style-type: none !important;
        }
        > li {
          text-transform: uppercase;
          padding: 6px;
          border-top: 1px solid white;
          > ul {
            display: none;
            &.mobileOpen {
              display: block;
            }
          }
          > ul li {
            text-transform: none;
          }
          a {
            color: white;
          }
        }
      }
      .menu_and_search {
        display: block;
        width: 70%;
        max-width: 290px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 100;
        right: 0px;
        top: 83px;
        font-size: 11px;
        color: white;
      }
    }
  }
}



.topLinksHolder {
  @include target-tablet-down() {
    display: none;
  }
}

.topLink {
  display: block;
  text-align: right;
  font-size: 13px;
  line-height: 1.5rem;
  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.globallocations {
  &:before {
    content: "\e906";
  }
}

.contactus {
  &:before {
    content: "\e905";
    font-size: 150%;
    color: #d40000
  }
}

.navToggle {
  display: none;
  @include target-tablet-down() {
    position: absolute;
    height: 80px;
    width: 80px;
    background-color: #232323;
    top: 0px;
    right: 0px;
    display: block;
    span {
      position: absolute;
      left: 27px;
      top: 33px;
      cursor: pointer;
      border-radius: 1px;
      height: 5px;
      width: 35px;
      background: white;
      position: absolute;
      display: block;
      content: '';
      &:before, &:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: 35px;
        background: white;
        position: absolute;
        display: block;
        content: '';
      }
      &:before {
        top: -10px;
      }
      &:after {
        bottom: -10px;
      }
    }
  }
}


.searchForm {
  display: flex;
  @include target-tablet-down() {
    padding: 0px 20px;
  }
  .searchFormField {
    float: left;
    width: 160px;
    outline: none;
    border: 2px solid $lightGrey;
    border-right: none;
    height: 24px;
    font-size: 12px;
    padding-left: 10px;
    margin-left: calc(100% - 195px);
    @include target-tablet-down() {
      width: calc(100% - 35px);
      margin-left: 0px;
    }
  }
  .searchFormSubmit {
    float: left;
    width: 35px;
    height: 24px;
    background-color: #535353;
    outline: none;
    border: 2px solid #535353;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e902";
    color: white;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    &:hover {
      background-color: $red;
      border-color: $red;
    }
  }
}

.linksAndLanguageWrapper{
  display: flex;
  justify-content: flex-end;
}

.languageSwitch{
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  .separator{
    margin: 0 5px;
  }
  .inActive{
    cursor: default;
    margin: 0 !important;
  }
  > a {
    color: #d40000 !important;
    line-height: 1.5rem;
  }
}

.languageSwitcher{
  @extend .languageSwitch;
  font-size: 13px;
  > a:hover {
    color: black !important;
  }
  @include breakpoint(medium-down) {
    display: none;
  }
}

.languageSwitcherM{
  @extend .languageSwitch;
  font-size: 12px;
  padding: 0px 24px;
  @include breakpoint(large) {
    display: none;
  }
}

