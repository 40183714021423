@import "../../../styles/variables";
.btn{
  font-family: 'Roboto', sans-serif;
  color: white;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 16px 20px;
  @include breakpoint(medium-down) {
    font-size: 16px;
    padding: 10px 20px;
  }
  &:hover{
    color: white;
  }
}

.black{
  background-color: black;
  border: 2px solid black;
  &:hover{
    border: 2px solid white;
  }
}

.red{
  background-color: #d40000;
  border: 2px solid #d40000;
  &:hover{
    border: 2px solid white;
  }
}

.transparent{
  background-color: transparent;
}

.outline{
  border: 2px solid white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    background-color: black;
  }
}

.downloadIcon{
  margin-left: 10px;
  @include breakpoint(medium-down) {
    margin-left: 35px;
  }
}
