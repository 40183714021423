@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 16px;
  margin: 20px 0;
  padding: 20px;

  @media screen and (min-width: 992px) {
    column-count: 2;
    column-gap: 40px;
    margin: 40px 0;
    padding: 65px $grid-gutter-width;
  }

  .icon_grid {
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  .slider {
    display: block;
    position: relative;
    padding-bottom:20px;

    :global {
      // Slick override
      .slick-dots {
        bottom: 0px;
        position: absolute;
        list-style: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
        text-align: center;
        li {
          width: 12px;
          height: 12px;
          padding-left: 0px;
          border-left: 0px;
          background-color: white;
          border-radius: 6px;
          display: inline-block;
          margin: 0px 5px;

          &.slick-active {
            background-color: $red;
          }
          button {
            display: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 12px;
            width: 12px;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    break-inside: avoid;

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .item_left {
      flex: 1;
      width: 100px;
      height: 100px;
      margin: 0px 10px 10px 10px;
      object-fit: contain;
      text-align: left;

      @media screen and (min-width: 992px) {
        margin: 5px 10px 5px 0;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .item_right {
      flex: 3;
    }

    dt {
      @include heleveticaStandard;
      text-transform: uppercase;
      font-weight: 700;
      color: $white;
      line-height: 22px;
      margin-bottom: 10px;
    }

    dd {
      @include heleveticaStandard;
      font-weight: 500;
      color: $white;
    }
  }
}
