@import "../../../styles/variables";

$redTab: #fe0000;
$activeRedTab: #ac0c0c;
$greyTab: #efeded;
$activeGreyTab: #e0e0e0;

.root {
  @include heleveticaStandard;
  display: grid;
  align-items: center;
  > * {
    height: 100%;
  }
  @include target-tablet-down {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title{
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  margin: 0 0 1rem 0;
  text-transform: none;
}

.column1{
  @include breakpoint(small) {
    
  }
}
.column2{
  @include breakpoint(small) {
    
  }
}

.tabsWrapper{
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  @include target-tablet-down {
    flex-direction: column;
  }
}

.tabItemTitle{
  @include helveticaCondensedBold;
  font-size: 1.1312rem;
}

.tabItem{
  width: 100%;
  padding: 0.75rem 0;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  p{
    margin: 0;
  }
}

.shape{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.parallelogram{
  &:before,
  &:after{
    @extend .shape;
    z-index: 1;
    width: 20px;
  }
  &:before{
    border-top: 42px solid $redTab;
    @include breakpoint(large) {
      border-left: 20px solid white;
    }
  }
  &:after{
    left: unset;
    right: 0;
    border-bottom: 42px solid $redTab;
    @include breakpoint(large) {
      border-right: 20px solid white;
    }
  }
}

.redTab{
  @extend .tabItem;
  color: white;
  &:first-child{
    &::before{
      @extend .shape;
      border-bottom: 42px solid $redTab;
      @include breakpoint(large) {
        border-right: 20px solid transparent;
      }
    }
  }
  &:not(:first-child){
    background-color: $redTab;
    @extend .parallelogram;
  }
}

.greyTab{
  @extend .tabItem;
  &:not(:last-child){
    background-color: $greyTab;
    @extend .parallelogram;
    &:before{
      border-top: 42px solid $greyTab;
    }
    &:after{
      border-bottom: 42px solid $greyTab;
    }
  }
  &:last-child{
    &::before{
      @extend .shape;
      border-top: 42px solid $greyTab;
      @include breakpoint(large) {
        border-left: 20px solid transparent;
      }
    }
  }
}

.activeTab{
  &.redTab{
    &.parallelogram{
      &:before{
        border-top: 42px solid $activeRedTab;
      }
      &:after{
        border-bottom: 42px solid $activeRedTab;
      }
    }
    &:first-child{
      &::before{
        border-bottom: 42px solid $activeRedTab;
      }
    }
    &:not(:first-child){
      background-color: $activeRedTab;
    }
  }
  &.greyTab{
    &.parallelogram{
      &:before{
        border-top: 42px solid $activeGreyTab;
      }
      &:after{
        border-bottom: 42px solid $activeGreyTab;
      }
    }
    &:not(:last-child){
      background-color: $activeGreyTab;
      &:before{
        border-top: 42px solid $activeGreyTab;
      }
      &:after{
        border-bottom: 42px solid $activeGreyTab;
      }
    }
    &:last-child{
      &::before{
        border-top: 42px solid $activeGreyTab;
      }
    }
  }
}

.tabContentWrapper{
  position: relative;
  overflow: hidden;
}

.redTabContent{
  color: white;
}

.bg{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.redBg{
  @extend .bg;
  background-color: $redTab;
}

.greyBg{
  @extend .bg;
  background-color: $greyTab;
}

.redParallelogramBg{
  @extend .bg;
  background-color: $activeRedTab;
  &:after{
    @extend .shape;
    right: -100%;
    left: unset;
    background-color: $activeRedTab;
    clip-path: polygon(100vmax 200vmax, 0% 0%, 0% 0%, 0% 100%);
  }
}

.greyParallelogramBg{
  @extend .bg;
  background-color: $activeGreyTab;
  &.notLast{
    &:after{
      @extend .shape;
      right: -100%;
      left: unset;
      background-color: $activeGreyTab;
      clip-path: polygon(100vmax 200vmax, 0% 0%, 0% 0%, 0% 100%);
    }
  }
  &.last{
    right: 0;
    background-color: $activeGreyTab !important;
    transform: skewX(30deg);
    transform-origin: 100% -50px;
  }
}

