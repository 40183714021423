@import "../../../styles/variables";

.root {
  background-color: #dbdbdb;
  color: $black;
  border-top: 1px solid white;
  margin-top: 40px;

  .global_direct {
    background-color: #e0e0e0;

    div {
      max-width: $max-width-nav;
      margin: auto;
      padding: 45px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 500;

      @media screen and (min-width: 980px) {
        justify-content: center;
        align-items: center;
      }


      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        gap: 10px;
        color: $black;

        &:hover {
          color: $red;

          svg {
            fill: $red;
          }
        }
      }

      svg {
        font-size: 24px;
        line-height: 1;
        margin-top: 2px;

      }
    }
  }

  .footer_content {
    max-width: $max-width-nav;
    margin: auto;
    padding-top: 45px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .follow_us h3 {
      font-size: 2.3em;
    }

    .icon_list {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      svg {
        font-size: 32px;
        margin-right: 10px;
        opacity: 1;

        &.linkedin {
          font-size: 42px;
        }
        &:hover {
          opacity: .8;
        }
      }

      .approved_accounts {
        display: flex;
        align-items: center;

        h4 {
          margin: 0;
          margin-left: -10px;
        }
      }
    }

    .footer_links {
      display: flex;
      flex-direction: column;;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 25px;

      @media screen and (min-width: 980px) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding-top: 45px;
      }

      ul {
        display: flex;
        gap: 10px;
        margin-left: 0;

        li {
          list-style: none;
          justify-content: flex-start;
          margin-left: 0;
          margin-bottom: 0;
          padding-left: 0;
          
          a {
            color: $black;

            &:hover {
              color: $red;
            }
          }
        }
        .divide {
          position: relative;
          border-left: 1px solid;
          padding-left: 10px;
        }
      }

      .copyright {
        text-transform: uppercase;
        font-weight: 500;
      }

    }
  }
  .back_to_top {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $black;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    opacity: 1;
    transition: ease-in-out 200ms opacity;

    &:hover {
      cursor: pointer;
      opacity: .9;
    }

    svg {
      transform: rotate(270deg);
      fill: $white;
      font-size: 32px;

    }

  }
}

