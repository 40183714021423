@import "../../../styles/variables";
$sliderHeight : 480px;

.root {
  height: $sliderHeight;
  position: relative;
  :global {
    // OVERRIDING SLICK SLIDER CSS
    .slick-dots {
      bottom: 31px;
      position: absolute;
      list-style: none;
      padding: 0px;
      margin: 0px;
      width: 100%;
      text-align: center;
      li {
        width: 12px;
        height: 12px;
        padding-left: 0px;
        border-left: 0px;
        background-color: white;
        border-radius: 6px;
        display: inline-block;
        margin: 0px 5px;
        &.slick-active {
          background-color: $red;
        }
        button {
          height: 0px;
          width: 0px;
          opacity: 0;
        }
      }
    }
    .slick-arrow {
      position: absolute; 
      z-index: 9999;
      top: 50%;
      transform: translateY(-50%);
    }
    .slick-prev {
      left: 40px;
    }
    .slick-next {
      right: 40px;
    }
    @include breakpoint(medium-down) {
      .slick-dots {
        li {
          background-color: gray;
        }
      }
    }
  }
}


.slider__item {
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  height: $sliderHeight;
  padding: 25px;
  @include vertical-align();
}

.slider__item_background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@include breakpoint(medium-down) {
  .slider__item_background {
      background-size: contain;
      background-position: center;
    }
}


.slider__item__slider_head {
  font-family: $boldCondensed;
  margin-top: 0px;
  font-size: 48px;
  line-height: 1.28;
  text-transform: uppercase;
  text-shadow: 2px 2px 20px black;
}

.slider__item__slider_description {
  @include helveticaCondensed;
  font-size: 40px;
  line-height: 1.28;
  font-weight: 300;
  margin: 35px 0px 0px 0px;
  text-shadow: 2px 2px 20px black;
}