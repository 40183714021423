@import "../../../styles/variables";

.root {
  background-color: #d40000;
  padding: 65px 56px;
  color: white;
  margin-bottom: 56px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @include breakpoint(medium-down) {
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    padding: 46px auto 62px;
  }
  @include breakpoint(small) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    padding: 50px 32px;
  }
}

.title{
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin: 0;
  text-transform: none;
  @include target-tablet {
    font-size: 35px;
    grid-column-start: span 2;
  }
  @include breakpoint(medium-down) {
    text-align: center;
    margin-bottom: 36px;
    font-size: 30px;
  }
}

.lButton{
  @include breakpoint(medium-down) {
    @include heleveticaStandard;
  }
}
