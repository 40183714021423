@import "../../../styles/variables";

.root {
  margin: 30px 0px;
  margin-top: 5px !important;
  padding: 20px 20px 20px 20px !important;
}

.red {
  background-color: $red;
}

.whereToBuy {
  display: block;
  width: 100%;
}

.heading {
  border-bottom : 3px solid black;
}

.select {
  background: #f0f0f0;
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #999;
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
}

.input {
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
}
