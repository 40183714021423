@import "../../../styles/variables";

.root {
}

.categorySelectorHolder {
  display: flex;
}

.categorySelector {
  border: 5px solid transparent;
  cursor: pointer;
  padding: 0px;
  margin-right: 10px;
  background-color: black;
  min-height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  color: white;
  position: relative;
  margin-bottom: 20px;
  flex: 1;
  background-size: cover;
  background-position: center center;
  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    border: 5px solid $grey;
  }

  &.selectedCategory {
    border: 5px solid $grey;
    .arrow_box {
      display: block;
      bottom: -5px;
      left: 0px;
      right: 0px;
      margin: 0 auto;
    }
  }

  .arrow_box {
    position: absolute;
    background: $grey;
    display: none;
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-width: 17px;
      margin-left: -17px;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $grey;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before, &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 16px solid transparent;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -16px;
  }

  .categoryTitle {
    display: inline-table;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    font-weight: 600;
    font-stretch: condensed;
    color: white;
    font-size: 20px;
    padding: 10px 0px;
    border-top: 4px solid #d40000;
    border-bottom: 4px solid #d40000;
  }

}

.panel {
  display: none;
  &.selectedPanel {
    display: block;
  }
}

.hero {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  list-style: none;
  padding: 54px 25px;
  color: white;
}

.sliderHead {
  margin-top: 0px;
  font-size: 48px;
  line-height: 1.28;
  text-transform: uppercase;
  text-shadow: 2px 2px 0px black;
}

.sliderSubHead {
  font-family: 'HelveticaNeueCondensed-CondensedRegular', sans-serif !important;
  font-stretch: condensed;
  font-size: 40px;
  line-height: 1.28;
  font-weight: 300;
  margin: 35px 0px;
  text-shadow: 2px 2px 0px black;
}