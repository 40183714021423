@import "../../../styles/variables";

.root {
}


.fixed {
  position: fixed;
}


.selectwrapper {
  position: relative;
}

.downarrow {
  position: absolute;
  right: 10px;
  top: 10px;
}



.modalwrap {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.95);
  left: 0px;
  z-index: 10001;
  overflow: scroll;
}

.modalForm {
  width: calc(100vw - 60px);
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid $red;
}

.modalHeading {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 4px solid #d40000;
  max-width: 258px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.closeLink {
  // display: block;
  text-align: right;
  float: right;
  font-family: $boldCondensed;
  font-size: 16px;
}

.select {
  background: #f0f0f0;
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #999;
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
}

.input {
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.filter_listing__results {
  margin-top: 10px;
}

.filter_listing__result {
  &:first-child {
  }
}

.filter_listing__result_title {
  font-size: 20px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 17px;
}

.filter_listing__result_details {
  padding-bottom: 28px;
  border-bottom: 2px solid #f0f0f0;
  strong {
    min-width: 90px;
    display: inline-block;
  }
}

.formWhere {
  width: 100%;
  background-color: $red;
  padding: 0px 25px;
}

.formWhereh {
    font-weight: 600;
    font-stretch: condensed;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    color: black;
    line-height: 1.4;
    padding-bottom: 23px;
    border-bottom: 4px solid black;
    margin: 22px 0px;
}