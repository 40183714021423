.heading {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    background-color: #D40000;
    padding: 12px 16px;
    margin-bottom: 30px;
}

.unitWrapper {
    padding: 40px;
    background-color: rgba(217, 217, 217, 0.25);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.unitItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.itemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
}

.itemTitle {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 19.8px */
    text-transform: uppercase;
}

.itemImage {
    width: 100%;
    height: auto;
    @media screen and (min-width: 768px) { 
        width: 230px;
        height: 230px;
    }
}
.buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    margin-bottom: 30px;
    @media screen and (min-width: 768px) { 
        flex-direction: row;
    }
}