@import "../../../styles/variables";

.root {
    font-family: $boldCondensed;
    background-color: $red;
    color: white;
    padding: 14px 18px;
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 300;
    @include roundedCorners;
    &:after {
      content: "\e903";
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $grey;
      padding-left: 13px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      font-size: 90%;
    }
    &:hover {
      color: white;
      background-color: $grey;
      &:after {
        color: $red;
      }
    }
  }