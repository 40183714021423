@import "../../../styles/variables";

.root {
  // border-top: 3px solid $red;
  width: 100%;
  position: sticky;
  z-index: 100;
  top: 0px;

  &.desktop {
    display: none;
    transition: ease-in-out 400ms all;
    @media screen and (min-width: 980px) {
      display: block;
    }
  }

  .navbar {
    padding: 0px 20px;
    position: relative;
    max-width: $max-width-nav;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    background-color: $white;
    height: 85px;

    .logo_link img {
      max-height: 50px;
      width: 100%;
      min-width: 120px;
      object-fit: contain;
      overflow: hidden;
    }
  }

  .nav_left {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      padding-right: 16px;
      margin-right: 16px;
      height: 72px;
      width: 100%;
    }

    .site_title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      margin-left: 16px;
    }
  }

  .nav__links {
    grid-area: 1 / 2 / 2 / 5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
      margin: 0;
      padding: 0;
      height: 100%;
      display: block;
      list-style: none;
      transition: ease-in-out 200ms all;

      &.active button, .active a {
        background-color: black;
        color: $white;
        outline: none;

        &:hover {
          background-color: black;
          color: $white;
        }
      }

      a,
      button {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        height: 85px;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: ease-in-out 200ms color;
        background-color: transparent;
        border: none;

        @media screen and (min-width: 1350px) {
          font-size: 18px;
          padding: 18px;
        }

        &:hover {
          color: $red;
          cursor: pointer;
        }
      }
    }
  }

  .nav_right {
    grid-area: 1 / 5 / 2 / 6;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .lang_switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .separator {
        display: block;
        padding-left: 3px;
        padding-right: 3px;
      }

      p,
      a {
        padding: 5px 3px;
        font-weight: 700;
        display: block;
        margin: 0;
        position: relative;
        color: #000;
      }

      p {
        font-weight: 700;
      }

      p:after {
        content: '';
        position: absolute;
        left: 3px;
        bottom: 0;
        width: 78%;
        margin: auto;
        height: 2px;
        background-color: $red;
      }

      a:hover:after {
        content: '';
        position: absolute;
        left: 3px;
        bottom: 0;
        width: 78%;
        margin: auto;
        height: 2px;
        background-color: black;
        opacity: .2;
      }

      .separator {
        margin: auto;
      }
    }
    .global_site_link {
      padding: 5px 3px;
      line-height: 1;
      fill: $black;

      &:hover svg {
        fill: $red;
      }
    }

    .search_container {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 8px;

      form {
        display: flex;

        button {
          background-color: transparent;
          border: 0;
        }
      }
    }

    .search_bar {
      border: none;
      height: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding-left: 20px;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
    }

    .search_input {
      overflow: hidden;
      transition: ease-out 400ms width;
      

      &.hidden {
        width: 0;
        overflow: hidden;
      }

      &.active {
        width: 500px;
        margin-right: 20px;
      }

      input {
        border: none;
        border-bottom: 2px solid black;
        font-size: 20px;
        font-style: italic;
        width: 100%;
        display: block;
        padding: 10px;
      }
    }
  }

  .dropdown__container {
    position: absolute;
    width: 100%;
    height: 100%;

    .close_btn {
      position: absolute;
      top: 32px;
      right: 36px;
      z-index: 1;

      &:hover {
        cursor: pointer;
        fill: $red;
        color: $red;
      }
    }

    .dropdown__overlay {
      position: fixed;
      top: -85px;
      left: 0;
      background-color: rgba(0, 0, 0, 0.8);
      height: 100vh;
      width: 100vw;
      z-index: 1;
    }

    .dropdown__container_inner {
      background-color: #f6f6f6;
      overflow-x: hidden;
      position: relative;
      max-width: $max-width-nav;
      margin: auto;
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: flex-start;
      transition: ease-in-out 400ms transform;

      h2 {
        margin: 0;
      }

      button {
        border: none;
        background-color: transparent;
        text-align: left;

        &:hover {
          color: $red;
        }
      }

      .link_button {
        display: inline-block;
      }

      .link_button a{
        display: flex;
        font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed",
          "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed",
          "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold",
          "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosCnBold", "Helvetica",
          "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
        align-items: center;
        margin: 25px 0;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 20px;
        background-color: $red;
        color: $white;
        border: 0;
        transition: ease-in-out 200ms all;

        &:after {
          content: "\e903";
          font-family: "icomoon" !important;
          margin-left: 10px;
          font-size: 14px;
          color: $black;
          transition: ease-in-out 200ms all;
        }

        &:hover {
          background-color: $black;
          color: $white;
          cursor: pointer;

          &:after {
            color: $red;
          }
        }
      }

      .dropdown__left {
        padding: 55px 0 45px 30px;
      }

      .dropdown__right {
        padding: 55px 0 45px;
        position: relative;
        overflow-y: hidden;
        overflow-x: hidden;

        ul {
          margin: 0;

          &:first-child {
            max-width: 50%;
          }

        }

        li {
          list-style: none;
          display: block;
          margin: 0;
          width: auto;
          padding-left: 0;

          .grandchild_link {
            padding-left: 12px;
            opacity: 0.75;
            color: $black;
            position: relative;
          }

          //   &.active {
          //     background-color: rgba(0, 0, 0, 0.1);
          //     padding-left: 10px;
          //     width: 100%;

          //     button {
          //       outline: none;
          //     }
          //   }

          button,
          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2px;
            padding: 10px 0;
            font-size: 18px;
            font-weight: 500;
            max-width: 300px;
            width: auto;
            transition: ease-in-out 400ms all;
            text-align: left;
            color: $black;

            &:hover {
              color: $red;
            }

            &.active {
              background-color: rgba(0, 0, 0, 0.1);
              padding: 10px 14px;
              width: 100%;
              max-width: 100%;
              outline: none;
              color: $red;

              svg {
                fill: $red;
              }
            }
          }
        }

        .list_right {
          background-color: #dddddd;
          width: 50%;
          margin-right: 0;
          position: absolute;
          top: 0;
          right: 0;
          height: calc(100vh - 85px);
          max-height: calc(100vh - 85px);
          overflow-y: scroll;
          scrollbar-width: none; 
          padding: 50px 0 45px 30px;
          transform: ease-in-out 400ms all;

          button,
          a {
            font-size: 16px;
            padding: 6px 0;
            color: $black;
          }
        }
      }
    }
  }
  .sr_only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}

@media screen and (min-width: 980px) {
  .red_border {
    background-color: $red;
    width: 100%;
    height: 3px;
    position: absolute;
    z-index: 200;
    top: 0px;
  }
}
