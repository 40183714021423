@import "../../../styles/variables";

.root {

}

.inputPanel {
  // border-right: 1px solid $red;
}

.itemgroup {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 10px 10px 0px;
}

.label {
  width: 100%;
  padding-top: 10px;
}

.input {
  width: 100%;
  padding: 12px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.unit {
  text-align: left;
  padding-top: 10px;
}

.OperatingCostRow {
  padding: 7px 10px 5px 10px;
  border-bottom: 1px solid #B1B0B0;
  &:first-child{
    border-bottom: 1px solid $red;
  }
  &:nth-child(even) {
    background-color: #e0e0e0;
  }
}

.OperatingCostTable {
  border: 1px solid $red;
}

.red {
  color: $red;
}

.right {
  text-align: right;
}