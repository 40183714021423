@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {
  nav {
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      display: flex;
      margin-bottom: 50px;
      list-style-type: none !important;
    }
    li {
      flex: 1;
      margin: 0px;
      padding-left: 0px;
      margin-right: 20px;
      border-left: 0px;
      a {
        display: block;
        width: 100%;
        margin-right: 20px;
        background-color: $red;
        font-size: 20px;
        text-align: center;
        padding: 14px;
        color: white;
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 2px;
        font-weight: 600;
        font-stretch: condensed;
        color: white;
        font-family: $boldCondensed;
        &:hover, &.active {
          background-color: $grey;
          &:after {
            content: " ";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 16px solid $grey;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -16px;
          }
        }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.content {

}

.modalClose {
  display: none;
  @include target-mobile {
    display: none;
  }
}

.contentTitleClass {
  font-weight: 600;
  font-stretch: condensed;
  font-size: 30px;
  border-left: 4px solid #d40000;
  padding: 0px;
  margin-top: 0px;
  text-transform: uppercase;
  padding-left: 18px;
  line-height: 1;
  padding-bottom: 10px;
  color: #232323;
  margin-bottom: 17px;
}


.close {
  @include target-mobile {
    // display: block;
    // position: fixed;
    // top: 0px;
    // right: 0vw;
    // transition: right 0.3s ease-in;
  }
}
