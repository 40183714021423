@import "../../../styles/variables";

.sectionTitle {
  font-family: $boldCondensed;
  font-weight: 600;
  font-stretch: condensed;
  font-size: 30px;
  border-left: 4px solid $red;
  padding: 0px;
  margin-top: 36px;
  text-transform: uppercase;
  padding-left: 18px;
  line-height: 1;
  padding-bottom: 10px;
  color: #232323;
  margin-bottom: 40px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 16px;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, auto);
    gap: 40px;
  }
}