@import "../../../styles/variables";

.root {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 30px 0;

  img {
    position: relative;
    height: 100%;
    width: 100%;
  }

  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      .hotspot {
        position: absolute;
        left: 0;
        top: 0;
        height: 18px;
        width: 18px;
        background-color: $red;
        border-radius: 25px;
        border: 1px solid $red;
        outline: 0;
        background-size: 8px 8px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: ease-in 250ms all;

        @media screen and (min-width: 992px) {
          height: 28px;
          width: 28px;
          border-radius: 25px;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }

        &:focus {
          transform: scale(1.2);
        }

        &.active {
          transform: scale(1.3);

          @media screen and (min-width: 992px) {
            transform: scale(1.5);
          }
        }

        &.inactive {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path stroke-width="30" stroke="%23D9D9D9" fill="%23D9D9D9" d="M256 80V48H192V80 224H48 16v64H48 192V432v32h64V432 288H400h32V224H400 256V80z"/></svg>');
          background-size: 8px 8px;
          background-repeat: no-repeat;
          background-position: center center;

          @media screen and (min-width: 992px) {
            background-size: 18px 18px;
          }
        }

        span {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        }
      }
    }

    .title {
      position: absolute;
      bottom: 15px;
      left: 0;
      color: #fff;
      font-size: 16px;
      width: 100%;
      text-align: center;
      z-index: 1;

      @media screen and (min-width: 992px) {
        left: auto;
        right: 35px;
        bottom: 40px;
        transform: none;
        width: auto;
        text-align: right;
      }
    }
  }
}
